<template>
  <div class="relative d-flex flex-column">
    <label v-if="!onlyInput" :for="name" class="form-label">
      {{ name }}
      <span v-if="required === true" class="text-danger">*</span>
    </label>
    <div
      :class="[
        {'d-flex input-group': $slots.buttonContent || $slots.buttonContentLeft},
        'has-validation',
      ]"
    >
      <BootstrapButton
        v-if="!textarea && $slots.buttonContentLeft"
        :id="`${id}-button-addon-left`"
        variant="secondary"
        style="
          border-left: 1px solid var(--bs-border-color) !important;
          background-color: var(--bs-body-bg);
        "
        :name="`${name}-button-addon-left`"
        :outlined="true"
        class="button-content"
        @click="$emit('addon-left-clicked')"
      >
        <slot name="buttonContentLeft" />
      </BootstrapButton>
      <textarea
        v-if="textarea === true"
        :id="id"
        v-model="model"
        :aria-label="ariaLabel || name"
        autocomplete="autocomplete"
        class="form-control"
        :class="[
          {'is-invalid': error.length > 0 && serverError === true},
          {'is-valid': error.length === 0 && serverError === true},
        ]"
        :placeholder="placeholder"
        :aria-required="required"
        :required="required"
        :maxlength="maxLength"
        rows="5"
        :disabled="disabled"
        data-testid="form-control.text-area"
        @focus="emit('focus')"
      />
      <input
        v-else
        :id="id"
        v-model="model"
        :aria-label="ariaLabel || name"
        :autocomplete="autocomplete ? 'on' : 'off'"
        class="form-control"
        :class="[
          {'is-invalid': error.length > 0 && serverError === true},
          {'is-valid': error.length === 0 && serverError === true},
          size ? `form-control-${size}` : '',
        ]"
        :placeholder="placeholder"
        :aria-required="required"
        :required="required"
        :maxlength="maxLength"
        :aria-describedby="`${name}-button-addon`"
        :disabled="disabled"
        :data-testid="id"
        @keydown.enter.prevent="$emit('enter')"
        @focus="emit('focus')"
      />
      <!-- Button to clear input text RND-2352 -->
      <IconButton
        v-if="model && !textarea && !disabled && ($slots.buttonContent || $slots.buttonContentLeft)"
        icon-name="material-symbols:close"
        class="input-group-text"
        @click="() => (model = '')"
      />

      <BootstrapButton
        v-if="!textarea && $slots.buttonContent"
        :id="`${id}-button-addon`"
        variant="secondary"
        style="
          border-left: 1px solid var(--bs-border-color) !important;
          background-color: var(--bs-body-bg);
        "
        :name="`${name}-button-addon`"
        :outlined="true"
        class="border-start-0 button-content"
        @click="$emit('addon-clicked')"
      >
        <slot name="buttonContent" />
      </BootstrapButton>
      <span
        role="alert"
        aria-atomic="true"
        class="text-danger invalid-feedback"
        :data-testid="
          textarea === true
            ? 'form-control.text-area.error-message'
            : 'form-control.input.error-message'
        "
      >
        {{ error.length > 0 ? error : `${name} is required` }}
      </span>
    </div>
    <small
      v-if="maxLength"
      :id="name"
      class="text-muted mt-1"
      :data-testid="maxLength && 'form-control.text-area.max-char' + maxLength"
    >
      {{ model.length }} / {{ maxLength }}
    </small>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  name: string;
  placeholder?: string;
  modelValue: string;
  ariaLabel?: string;
  error?: string;
  required?: boolean;
  textarea?: boolean;
  maxLength?: number;
  onlyInput?: boolean;
  disabled?: boolean;
  serverError?: boolean;
  autocomplete?: boolean;
  size?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  ariaLabel: '',
  error: '',
  required: false,
  textarea: false,
  maxLength: undefined,
  onlyInput: false,
  disabled: false,
  serverError: false,
  autocomplete: true,
  size: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  'onUpdate:modelValue': [value: string]; // Required for vnodes, i.e h() function
  enter: [value: void];
  'addon-clicked': [value: void];
  'addon-left-clicked': [value: void];
  focus: [value: void];
}>();

/**
 * "The return type is currently ignored and can be any, but we may
 * leverage it for slot content checking in the future."
 * @see {@link https://vuejs.org/api/sfc-script-setup.html#defineslots}
 */
defineSlots<{
  buttonContent?: unknown;
  buttonContentLeft?: unknown;
}>();

const model = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emit('update:modelValue', value);
    emit('onUpdate:modelValue', value);
  },
});

const id = computed(() =>
  props.name
    ?.toLowerCase()
    .replace(/\s/g, '')
    .replace(/\([^()]*\)/g, ''),
);
</script>
<style lang="scss" scoped>
:deep(.input-group) > .btn-outline-secondary {
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}

:deep(.form-control::placeholder) {
  color: #96a0b5;
}

[data-bs-theme='light'] {
  .button-content {
    color: var(--bs-secondary);
  }
}
</style>
